.game-controls {
    width: "100%";
    height: "100%";
}

.column {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    height: 100%;
}

.row-1 {
    display: flex;
    flex-direction: row;
    flex-basis: 100%;
    flex: 1;
}
